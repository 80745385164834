import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./CharacterSheet.css";

const FontDemo = (props) => {
	return (
		<div className={`${props.name} FontDemo`}>
			<h1>{props.name.replace(/-/g, ' ')}</h1>
			<p>A deck of many things, also known as deck of hazards, was an assortment of magical cards or metallic plates that were arranged in a set deck. They were enchanted with great magic that was never to be used lightly.</p>
			{/* <p>While each deck of many things was different, they held some common properties. The majority of decks found contained 13 cards, while approximately 1/4 of them held 22. They were typically made of vellum or ivory, but could also be cast from thin sheets of metal. They were often kept in a small pouch or ornate box.</p>
			<p>The cards were named and each was adorned with a unique portrait that shimmered with a radiance of power. A 13-card deck included the sun, moon, star, throne, key, knight, the void, flames, skull, ruin, euryale, rogue and jester cards, while a 22-card deck added the vizier, comet, the fates, gem, talons, idiot, donjon, balance and fool.</p> */}
		</div>
	)
}

const CharacterSheet = () => {
	return (
		<div className='CharacterSheet'>
			<FontDemo name='Quintessential' />
			<FontDemo name='Almendra' />
			<FontDemo name='Eagle-Lake' />
			<FontDemo name='Jim-Nightshade' />
			<FontDemo name='Fondamento' />
			<FontDemo name='Balthazar' />
			<FontDemo name='Germania-One' />
		</div>
	);
};

export default CharacterSheet;