import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./TabletopTools.css";
import ToolSelector from './ToolSelector';
import Header from '../app/Header';

const TabletopTools = () => {
	return (
		<>
			<ToolSelector />
			<div className="TabletopTools">
				Select a tool above...
			</div>
		</>
	);
}

export default TabletopTools;
