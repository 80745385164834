import React from 'react'
import { Link } from 'react-router-dom'
import './ProjectCard.css'

export default function ProjectCard(props) {
	return (
		<Link className='ProjectCard' to={props.link}>
			<img src={props.imagePath} />
			<p className='card-name'>{props.name}</p>
		</Link>
	)
}
