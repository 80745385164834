import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./CitizensGuideToRavnica.css";
import Header from '../app/Header';

const CitizensGuideToRavnica = () => {
	return (
		<>
			<Header />
			<div className="CitizensGuideToRavnica">
				<img src='/plaza-of-the-guildpact.jpg' />
				<img src='/glass-of-the-guildpact.jpg' />
				<img src='/hallowed-fountain.jpg' />
			</div>
		</>
	);
}

export default CitizensGuideToRavnica;
