// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import reportWebVitals from "./reportWebVitals";

import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import "./index.css";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyB92FgxHOQwAczRUJ-ZMcJVHHgAt8kT2aA",
	authDomain:
		"natetaylordev-website.firebaseapp.com",
	projectId: "natetaylordev-website",
	storageBucket:
		"natetaylordev-website.appspot.com",
	messagingSenderId: "952549360858",
	appId: "1:952549360858:web:9441d4988f37a4494951e3",
	measurementId: "G-N4B28W3DFV",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
