import {
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";
import { ROUTES } from "../routes";
import Header from "./Header";

const router = createBrowserRouter(ROUTES);

export default function App() {
	let darkMode =
		window.matchMedia &&
		window.matchMedia(
			"(prefers-color-scheme: dark)"
		).matches;
	return (
		<div className="App">
			{/* <Header /> */}
			<RouterProvider className='router' router={router} />
		</div>
	);
}
