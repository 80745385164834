import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./PointBuy.css";
import ToolSelector from './ToolSelector';

const PointBuy = () => {

	const Ability = (props) => {
		return <div className="ability">
			{props.name}
			<input type="number" /> + <input type="number" /> = 77
		</div>
	}

	return (
		<>
			<ToolSelector />
			<div className="PointBuy">
				<Ability name="STR" />
				<Ability name="DEX" />
				<Ability name="CON" />
				<Ability name="WIS" />
				<Ability name="INT" />
				<Ability name="CHA" />
			</div>
		</>
	);
}

export default PointBuy;
