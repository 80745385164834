import HomePage from "./app/Home";
import CatchEmAllPage from "./catch-em-all/CatchEmAll";
import CitizensGuideToRavnica from "./citizens-guide-to-ravnica/CitizensGuideToRavnica";
import TabletopTools from "./tabletop-tools/TabletopTools";
import ActiveTimeBattle from "./tabletop-tools/ActiveTimeBattle";
import PointBuy from "./tabletop-tools/PointBuy";
import CharacterSheet from "./character-sheet/CharacterSheet";

export const ROUTES = [
	{
		path: "/",
		element: <HomePage />,
		// errorElement: 
	},
	// {
	// 	path: "/league-champ-mastery",
	// 	element: <CatchEmAllPage />,
	// 	isProject: true,
	// 	name: "Custom Dashboards — League Of Legends",
	// 	image: "/league.jpg",
	// },
	{
		path: "/citizens-guide-to-ravnica",
		element: <CitizensGuideToRavnica />,
		isProject: true,
		name: "Citizen's Guide to Ravnica",
		image: "Citizens Guide Thumbnail.jpg",
		hideHeader: true,
	},
	{
		path: "/character-sheet",
		element: <CharacterSheet />,
		isProject: true,
		name: "Character Sheet",
		image: "Tabletop Tools Thumbnail.jpg",
		hideHeader: true,
	},
	{
		path: "/tabletop-tools",
		element: <TabletopTools />,
		isProject: true,
		name: "Tabletop Tools",
		image: "Tabletop Tools Thumbnail.jpg",
		hideHeader: true,
	},
	{
		path: "/tabletop-tools/active-time-battle",
		element: <ActiveTimeBattle />,
		isProject: false,
		hideHeader: true,
	},
	{
		path: "/tabletop-tools/point-buy",
		element: <PointBuy />,
		isProject: false,
		hideHeader: true,
	},
];
