import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./ActiveTimeBattle.css";
import ToolSelector from './ToolSelector';
import ActiveTimeBattleCard from './ActiveTimeBattleCard';
import Header from '../app/Header';

const ActiveTimeBattle = () => {

	const [characters, setCharacters] = useState([]);

	const CharacterList = (props) => {
		return <div className="cardContainer">
			{props.characters && props.characters.map((c) => <ActiveTimeBattleCard characterData={c} />)}
		</div>
	}

	const addCharacter = () => {
		setCharacters([...characters, {
			initiative: randomInt(1, 30),
			currentPoints: 0,
			maxPoints: 1000,
			turns: 0,
		}]);
	}

	const removeAllCharacters = () => {
		setCharacters([]);
	}

	const goToNextTurn = async () => {
		setCharacters(characters.map(c => {
			if (c.currentPoints >= c.maxPoints) {
				c.currentPoints = 0;
			}
			return c;
		}));

		const updateStep = 3;
		const updateWait = 1;
		while (characters.every(c => c.currentPoints < c.maxPoints)) {
			await milliseconds(updateWait);
			setCharacters(characters.map(c => {
				c.currentPoints = Math.min(c.currentPoints + ((1 + (c.initiative / 100)) * updateStep), c.maxPoints);
				if (c.currentPoints >= c.maxPoints) {
					c.turns++;
				}
				return c;
			}));
		}
	}

	function milliseconds(amount) {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve('resolved');
			}, amount);
		});
	}

	return (
		<>
			<ToolSelector />
			<div className="ActiveTimeBattle">
				<div className="controls">
					<button onClick={addCharacter} >Add Character</button>
					<button onClick={removeAllCharacters} >Remove All Characters</button>
					<button onClick={goToNextTurn} >Go to next turn</button>
				</div>
				<CharacterList characters={characters} />
			</div>
		</>
	);
}

function randomInt(min, max) {
	return Math.floor(Math.random() * (max - min + 1) + min);
}

export default ActiveTimeBattle;
